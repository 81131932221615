import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import isNull from 'lodash/isNull';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import { isOdd } from '../utils/utils';
import { styleConfig } from '../../config/config';

import Section from './Common/Section';
import Title from './Common/Title';
import RouteLink from './RouteLink';

const ContentModuleBrandBlocks: React.FunctionComponent<ContentModuleBrandBlocksProps> = (
  props
) => {
  const { subtitle, headline, rows } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
    },
    description: {
      transition: 'all 0.8s cubic-bezier(.76,.53,.22,.86)',
      position: 'relative',
      display: 'block',
      fontSize: 16,
      fontWeight: 600,
      color: styleConfig.color.text.white,
      opacity: 0,
      width: '90%',

      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: '-100px',
        opacity: 0,
      },
    },
    arrow: {
      verticalAlign: 'middle',
      display: 'inline-flex',
      width: 42,
      height: 42,
      transition: '0.3s ease-in-out all',
      opacity: 0,
      marginLeft: 10,

      [theme.breakpoints.down('lg')]: {
        opacity: 1,
      },
    },
    imageRoot: {
      height: '35rem',

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
      },
    },
    image: {
      transition: 'all .8s cubic-bezier(.76,.53,.22,.86)',
      zIndex: 0,
      transform: 'scale(1)',

      [theme.breakpoints.down('md')]: {
        height: '35rem',
      },

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
      },
    },
    content: {
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
      zIndex: 555,
      display: 'block',
      padding: '3rem 2rem',
      height: '28.1rem',
      width: '70%',

      [theme.breakpoints.down('lg')]: {
        height: '35rem',
        padding: '2rem',
        width: '100%',
      },

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
        padding: '4rem',
        width: '100%',
      },
    },
    model: {
      position: 'relative',
      overflow: 'hidden',
      transition: '0.3s ease-in-out all',
      background: 'transparent',
      willChange: 'transform',
      transform: 'scale(1.0)',

      '&:before': {
        transition: '0.3s ease-in-out all',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.30)',
        content: "''",
        zIndex: 1,
      },

      '&:hover': {
        '& $description': {
          [theme.breakpoints.up('lg')]: {
            bottom: '75px',
            opacity: 0.8,
          },

          [theme.breakpoints.up('xl')]: {
            bottom: '200px',
            opacity: 0.8,
          },
        },
        '& $arrow': {
          transform: 'translate3d(15px, 0, 0)',
          opacity: 1,
          willChange: 'transform',
        },

        '&:before': {
          background: 'rgba(0, 0, 0, 0.50)',
        },

        '& $image': {
          transform: 'scale(1.1)',
        },

        '& $subtitle': {
          [theme.breakpoints.up('lg')]: {
            bottom: '4rem',
          },
        },
      },
    },
    sectionTitle: {
      position: 'relative',
      display: 'block',
      padding: '6rem 0',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: '6rem 1rem',
      },
    },
    title: {
      color: styleConfig.color.text.white,
      display: 'block',
      margin: '0',
      fontFamily: 'Bai Jamjuree',
      fontSize: '3.5rem!important',
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        fontSize: '2.4rem!important',
      },
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 600,
      color: styleConfig.color.text.gold,

      '&:hover': {
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
          '.arrow': {
            opacity: 1,
          },
        },
      },
    },
  }));

  const classes = useStyles();
  const columnTotals = rows.map((row) => row.cols.length);
  const totalItems = columnTotals.reduce((a, b) => a + b, 0);

  return (
    <Section nopad>
      <div className={classes.root}>
        <React.Fragment>
          {!isNull(headline) && !isNull(subtitle) && headline && (
            <Grid container justifyContent="center">
              <Grid item sm={12} md={10}>
                <Box className={classes.sectionTitle}>
                  <Title
                    component="h2"
                    alignment="center"
                    isSubtitle
                    subtitleText={subtitle}
                    subtitleType="subtitle1"
                    titleCaps
                    titleColor={styleConfig.color.text.darkgrey}
                    titleHeavy
                    titleSize="h2"
                    titleText={headline}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container>
            {rows &&
              rows.map((row) => {
                return (
                  row &&
                  row.cols.map((col) => {
                    const image = getImage(col.image.localFile);
                    const bgImage = convertToBgImage(image);

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={isOdd(totalItems) ? 4 : 6}
                        xl={isOdd(totalItems) ? 4 : 6}
                        key={uniqueId()}
                        style={{ background: styleConfig.color.bg.darkgrey }}
                      >
                        {col && col.link && col.link.url && (
                          <RouteLink href={col && col.link && col.link.url}>
                            <div className={classes.model}>
                              {col.image && col.image.localFile && (
                                <div className={classes.imageRoot}>
                                  <BackgroundImage
                                    fadeIn={false}
                                    style={{ height: '100%' }}
                                    className={classes.image}
                                    alt={col.image.alt_text}
                                    {...bgImage}
                                  />
                                </div>
                              )}

                              <div className={classes.content}>
                                {col && (
                                  <React.Fragment>
                                    <h3 className={clsx([classes.title])}>
                                      {col.title}

                                      {col.link && col.link.url && (
                                        <ArrowRightAltIcon
                                          color="primary"
                                          className={classes.arrow}
                                        />
                                      )}
                                    </h3>

                                    {col.subtitle && (
                                      <Typography className={clsx(classes.subtitle)}>
                                        {col.subtitle}
                                      </Typography>
                                    )}

                                    <div
                                      className={classes.description}
                                      dangerouslySetInnerHTML={{ __html: col.description }}
                                    />
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </RouteLink>
                        )}
                      </Grid>
                    );
                  })
                );
              })}
          </Grid>
        </React.Fragment>
      </div>
    </Section>
  );
};

interface ContentModuleBrandBlocksProps {
  subtitle: string;
  headline: string;
  rows: {
    cols: {
      contents: string;
      text_align: string;
      subtitle: string;
      link: {
        url: string;
      };
      title: string;
      description: string;
      image: {
        alt_text: string;
        localFile: any;
      };
    }[];
  }[];
}

export default ContentModuleBrandBlocks;
